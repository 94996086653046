import React from "react";
import { Link } from "gatsby";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import ParagraphTypography from "../Typography/ParagraphTypography";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import quotesImg from "../../../images/v2/testimonials-quotes.svg";
import testimonialBgImg from "../../../images/v2/testimonial-bg.svg";

const useStyles = makeStyles(() => ({
	bottomTestimonial: {
		background: `url(${testimonialBgImg}) no-repeat`,
		backgroundSize: "195px 223px",
		height: "auto",
		overflow: "auto",
		"& .gatsby-image-wrapper": {
			margin: "2.65rem 0 0 3rem",
			float: "right",
			height: "auto",
			boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
			borderRadius: "10px",
			width: "400px",
			maxWidth: "80%",
			aspectRatio: 1,
		},
	},
	content: {
		"& br": {
			display: "block",
			height: 10,
			content: `""`,
		},
	},
	moreLink: {
		display: "flex",
		justifyContent: "end",

		"& a": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#00b8d8",
			textDecoration: "underline",
		},
	},
}));

const SingleTestimonial = ({ image, content, position, company, name }) => {
	const classes = useStyles();
	return (
		<Box component={Grid} container justify="center" mb={"30px !important"} spacing={2}>
			<Grid item md={5} sm={5} xs={12}>
				<Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
					<Box className={classes.bottomTestimonial} display="inline-block">
						{image}
					</Box>
				</Box>
			</Grid>
			<Grid item md={1} sm={1} xs={12}>
				<Divider light orientation="vertical" />
			</Grid>
			<Grid item md={6} sm={6} xs={12}>
				<Box>
					<Box textAlign="left !important">
						<Box alt="CRM Customer Testimonial" component="img" src={quotesImg} width={33} />
					</Box>
					<ParagraphTypography className={classes.content} fontSize={16} lineHeight={1.7} mb={4} textAlign="left">
						{content}
					</ParagraphTypography>
					<Box fontSize={18} fontWeight="fontWeightBold" textAlign="left">
						{name}
					</Box>
					<ParagraphTypography fontSize={18} mt={0} textAlign="left">
						{position}
						{company ? `, ${company}` : ""}
					</ParagraphTypography>

					<div className={classes.moreLink}>
						<Link to="/crm-testimonials">Read More...</Link>
					</div>
				</Box>
			</Grid>
		</Box>
	);
};

SingleTestimonial.propTypes = {
	image: PropTypes.node,
	name: PropTypes.string,
	content: PropTypes.node,
	position: PropTypes.string,
	company: PropTypes.string,
};

export default SingleTestimonial;
