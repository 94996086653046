import React from "react";
import HeaderTypography from "./Typography/HeaderTypography";
import Box from "@material-ui/core/Box";
import ParagraphTypography from "./Typography/ParagraphTypography";
import PropTypes from "prop-types";
import useWidth from "../../hooks/useWidth";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	accordionTitle: {
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px !important",
		},
	},
}));

const AccordionHeader = ({ img, alt, title, description, header = "h3", ...props }) => {
	const width = useWidth();
	const classes = useStyles();
	return (
		<Box {...props}>
			<HeaderTypography
				alignItems="center"
				component={header}
				display="flex"
				fontSize="1.75rem"
				fontWeight={700}
				lineHeight={1.4}
				mb={2}
				textAlign="inherit"
				className={classes.accordionTitle}
			>
				{img && <Box alt={alt || title} component="img" height={{ sm: 40, xs: 40 }[width] || 89} mr={{ sm: 2, xs: 2 }[width] || 3} src={img} />}
				{title}
			</HeaderTypography>
			{description && (
				<ParagraphTypography fontSize={18} textAlign={{ sm: "center", xs: "center" }}>
					{description}
				</ParagraphTypography>
			)}
		</Box>
	);
};

AccordionHeader.propTypes = {
	img: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	header: PropTypes.string,
};

export default AccordionHeader;
