import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import salesProcessIcon from "../images/v2/dashboard/icon1.svg";
import analyzeIcon from "../images/v2/dashboard/Analyze lead conversions_1.svg";
import manageIcon from "../images/v2/dashboard/Manage relationshipsbetter.svg";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Collapse from "@material-ui/core/Collapse";
import AccordionHeader from "../components/v2/AccordionHeader";
import marketingCampain from "../images/v2/integration/campaign-icon.svg";
import useWidth from "../hooks/useWidth";
import Hidden from "@material-ui/core/Hidden";
import WhiteButton from "../components/v2/WhightButton";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import workWithEmailFeatureImg from "../images/v2/integration/work-with-email-bg.svg";
import dashboardimg1 from "../images/v2/dashboard/Dashboard-1.svg";
import dashboardimg2 from "../images/v2/dashboard/Dashboard-2@2x.svg";
import dashboardimg3 from "../images/v2/dashboard/Dashboard-3@2x.svg";
import dashboardimg4 from "../images/v2/dashboard/Dashboard-4@2x.svg";
import dashboardimg5 from "../images/v2/dashboard/Dashboard-5@2x.svg";
import dashboardimg6 from "../images/v2/dashboard/Dashboard-6@2x.svg";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";

const useStyles = makeStyles((theme) => ({
	listItemText: {
		"& *": {
			fontSize: 20,
			color: "#2e3f4f",
			[theme.breakpoints.down("sm")]: {
				fontSize: 16,
			},
		},
	},
	listItemIcon: {
		color: "#08a742",
		fontSize: 28,
	},
	btn: {
		// minWidth: 307,
		// minHeight: 52,
		// fontWeight: 600,
		// fontSize: 20,
	},
	testimonialBg: {
		background: `url(${testimonialBgImg}) no-repeat`,
		backgroundSize: "195px 223px",
	},
	requestDemoRoot: {
		background: `url(${requestDemoBGImg})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	workWithEmailFeature: {
		background: `url(${workWithEmailFeatureImg}) center bottom`,
		backgroundSize: "cover",
	},
	img: {
		maxWidth: "100%",
		height: "auto",
	},
	dashboardBannerImg: {
		maxWidth: "100%",
		height: "auto",
		boxShadow: "0px 8px 25px #aaa",
		borderRadius: "12px 0 0 12px",
	},
	campaignsBg: {
		backgroundImage: "linear-gradient(to top, #e7f2fe, rgba(231, 242, 254, 0.57) 90%, rgba(231, 242, 254, 0))",
	},
	testimonialCustomerImg: {
		maxWidth: "90%",
		width: "290px",
		borderRadius: "10px",
		boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
		marginTop: "2.65rem",
		marginLeft: "3rem",
	},
	header: {
		backgroundImage: "linear-gradient(to bottom, #e7f2fe, rgba(233, 243, 254, 0.91) 46%, rgba(255, 255, 255, 0))",
	},
}));

const client = {
	image: (
		<StaticImage
			alt="Pepper Cloud+TechData Corporation"
			layout="constrained"
			src="../images/v2/testimonials-images/sally-see-peppercloud-customer.png"
		/>
	),
	name: "Sally See",
	position: "Marketing Manager",
	company: "Tec D Distribution Sdn Bhd, Malaysia",
	content: (
		<React.Fragment>
			Pepper Cloud CRM has made it easier for the updated information to be accessible to everyone in our company. Its
			association feature allows linking the business and activity records, helping us to track all present and past
			interactions with the customer giving sales access to more information, allowing a higher level opportunity
			conversion.
			<br />
			Our marketing team also utilizes that information to run targeted marketing campaigns. The CRM is good not only in
			tracking and forecasting sales but also in providing custom graphs, page views and fields. Another good thing with
			Pepper Cloud CRM is that they take feedback seriously. We feel that we do have a say in the product roadmap.
		</React.Fragment>
	),
};

const Dashboard = () => {
	const width = useWidth();
	const classes = useStyles();
	const [activeSalesProcess, setActiveSalesProcess] = React.useState(0);
	const [activeAnalyzeLead, setActiveAnalyzeLead] = React.useState(0);
	const [activeRelationships, setActiveRelationships] = React.useState(0);
	const [activeContact, setActiveContact] = React.useState([true, true]);

	const relationships = [
		{
			title: "Sales activities",
			description: (
				<>
					Understand how your team is spending the time. Get an overview of the{" "}
					<a href="https://blog.peppercloud.com/sales-activities-building-blocks-of-sales-process/">
						{" "}
						number of activities{" "}
					</a>{" "}
					created over time, filter the activity dashboard view and get the reports on which you can act.
				</>
			),
		},
		{
			title: "Activity type",
			description:
				"Break the performance by activity type such as task created, meetings finalised, calls logged, emails sent out, documents uploaded, etc. and see what is helping in increasing the sales and effective lead management.",
		},
	];

	const security = [
		"Avoid the bumps and repetition of mistakes that can hit your numbers.",
		"Analyse trends across the organisation and perform better sales forecasting.",
		"Monitor team productivity and the elements that led to success in selling.",
		"Identify the bottlenecks and design a roadmap to improve your team’s performance.",
	];

	const salesProcess = [
		{
			title: "Sales performance",
			description: (
				<>
					Determine the number and value of the won opportunities, top performers, visualise the{" "}
					<a href="https://peppercloud.com/sales-pipeline-management-tool">sales pipeline</a>, and track activities that lead to winning
					opportunities. Zoom in to the performance targets and prevent any sort of performance issues with the help of
					the sales performance report.
				</>
			),
		},
		{
			title: "Sales leaderboards",
			description:
				"Apart from the top numbers, get details on the top and bottom 10 opportunities by account, amount, and date. Identify and focus on improving the performance lags with the help of sales leaderboards.",
		},
	];
	const analyzeLead = [
		{
			title: "Lead KPIs",
			description: (
				<>
					Access converted or rejected lead numbers, monitor the performance of your sales funnel, and compare the leads
					on the leaderboard, with the help of the <a href="https://peppercloud.com/">best sales CRM</a>.
				</>
			),
		},
		{
			title: "Drill into details",
			description:
				"Monitor leads dashboard by date range or filter by your team and users. Get more granularity with chart level filters. With comprehensive details, start filling up your pipeline, manage your leads, and start selling efficiently.",
		},
	];

	const campaigns = [
		{
			title: "Real-time stats",
			description:
				"View performance reports on your campaign instantly. Analyse open rates, click rates, delivery rates, users subscribed and unsubscribed, the best time to send campaigns, etc. with the help of the Mailchimp dashboard.",
		},
		{
			title: "Compare and learn",
			description: (
				<>
					A small change can impact the results. Use A/B testing campaigns, identify those changes that can create a
					better impact among your audience and implement them as a part to improve your{" "}
					<a href="https://blog.peppercloud.com/sales-metrics-a-master-guide/">sales metrics</a>.
				</>
			),
		},
	];
	return (
		<>
			<SEO
				canonical="/sales-dashboard"
				description="Analytical CRM with best sales dashboards to access a visual represenation of sales, detect anomalies, monitor your key sales performance indicators, forecast sales and more."
				keywords="Sales Dashboard,CRM dashboard,data visualization dashboard,best sales dashboards,CRM Analytics,sales analytics,sales Lead dashboard"
				ogData={{
					"og:image": [
						"/meta/og/1X1/dashboard.png",
						"/meta/og/1024X512/dashboard.png",
						"/meta/og/1200X630/dashboard.png",
					],
				}}
				pathname="/sales-dashboard"
				title="Best Sales Dashboard | CRM Analytics | Analytical CRM | Pepper Cloud"
			/>
			<Box className={classes.header}>
				<Box alignItems="center" component={Grid} container justify="center">
					<Grid item md={6} sm={12}>
						<Box display="flex" justifyContent={{ sm: "center", xs: "center", md: "flex-end" }} mt={{ md: 10 }}>
							<Box maxWidth={620} p={2}>
								<HeaderTypography textAlign={{ sm: "center", xs: "center", md: "left" }} component="h1">
									Transform raw data into intelligent insights with CRM dashboard
								</HeaderTypography>
								<ParagraphTypography mb={6} mr={{ md: 4 }} textAlign={{ sm: "center", xs: "center" }[width]}>
									Fast track your sales process by accessing overview reports and analysing the{" "}
									<a href="https://blog.peppercloud.com/sales-metrics-a-master-guide/">key performance metrics </a> in
									one place. Gain action-oriented insights on sales, leads, activities, and campaigns with comprehensive
									analytics.
								</ParagraphTypography>
								<Box mb={{ sm: 4, xs: 4 }} textAlign={{ sm: "center", xs: "center" }[width] || "left"}>
									<Button
										className={classes.btn}
										color="secondary"
										component={Link}
										size="large"
										to="/contact"
										variant="contained"
									>
										Show me how
									</Button>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item md={6} sm={12}>
						<Box mt={{ md: 10 }} pl={2} textAlign="right">
							<img alt="Visual of data through CRM Dashboard" className={classes.dashboardBannerImg} src={dashboardimg1} />
						</Box>
					</Grid>
				</Box>
				<Box mt={{ sm: 6, xs: 5, md: 10 }}>
					<Divider light />
				</Box>
			</Box>

			<Box mb={{ md: 6 }} />

			<Container>
				<Grid alignItems="center" container justify="center">
					<Grid item md={6} sm={12}>
						<Box display="flex" justifyContent={{ sm: "center", md: "flex-end" }}>
							<Box maxWidth={620} p={1}>
								<AccordionHeader
									alt="Sales growth with CRM"
									description="Win opportunities without limits. Effortlessly monitor your sales cycle and gauge your team performance through an intuitive sales dashboard."
									header="h2"
									img={salesProcessIcon}
									title="Take control of your sales process"
								/>
								<Box mb={1} mt={6}>
									<Divider light />
								</Box>
								{salesProcess.map((each, index) => (
									<div key={each.title}>
										<Box my={1} p={2}>
											<Box
												alignItems="center"
												display="flex"
												justifyContent="space-between"
												onClick={() => setActiveSalesProcess(index === activeSalesProcess ? -1 : index)}
											>
												<Box color="text.secondary" component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
													{each.title}
												</Box>
												<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
													{index !== activeSalesProcess ? (
														<AddIcon fontSize="small" />
													) : (
															<RemoveIcon fontSize="small" />
														)}
												</IconButton>
											</Box>
											<Collapse in={activeSalesProcess === index}>
												<ParagraphTypography fontSize={16} mr={6} mt={2}>
													{each.description}
												</ParagraphTypography>
											</Collapse>
										</Box>
										<Divider light />
									</div>
								))}
							</Box>
						</Box>
					</Grid>
					<Grid item md={6} sm={12}>
						<Box textAlign="right">
							<img alt="Visual of sales  dashboard" className={classes.img} src={dashboardimg2} />
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Box mb={{ md: 6 }} mt={{ md: 10 }}>
				<Divider light />
			</Box>
			<Container>
				<Grid
					alignItems="center"
					container
					direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
					justify="center"
				>
					<Grid item md={5} sm={12}>
						<img alt="Visual of sales lead dashboard" className={classes.img} src={dashboardimg3} />
					</Grid>
					<Grid item md={1} sm={12}></Grid>
					<Grid item md={6} sm={12}>
						<Box display="flex" justifyContent={{ sm: "center", md: "flex-start" }}>
							<Box maxWidth={620} p={1}>
								<AccordionHeader
									alt="Sales performance report"
									description="With a simple, visually appealing sales lead dashboard, audit how your team members are progressing towards lead conversion goals."
									header="h2"
									img={analyzeIcon}
									title="Visualise lead conversions with views"
								/>
								<Box mb={2} mt={3}>
									<Divider light />
								</Box>
								{analyzeLead.map((each, index) => (
									<div key={each.title}>
										<Box my={1} p={2}>
											<Box
												alignItems="center"
												display="flex"
												justifyContent="space-between"
												onClick={() => setActiveAnalyzeLead(index === activeAnalyzeLead ? -1 : index)}
											>
												<Box component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
													{each.title}
												</Box>
												<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
													{index !== activeAnalyzeLead ? <AddIcon fontSize="small" /> : <RemoveIcon fontSize="small" />}
												</IconButton>
											</Box>
											<Collapse in={activeAnalyzeLead === index}>
												<ParagraphTypography fontSize={16} mr={6} mt={2}>
													{each.description}
												</ParagraphTypography>
											</Collapse>
										</Box>
										<Divider light />
									</div>
								))}
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Container>

			<Box mb={{ md: 6 }} mt={{ md: 10 }}>
				<Divider light />
			</Box>
			<Container>
				<Grid alignItems="center" container justify="center">
					<Grid item md={6} sm={12}>
						<Box display="flex" justifyContent="center">
							<Box maxWidth={650} mt={4} p={1}>
								<AccordionHeader
									alt="Revenue forecast"
									description="Focus on improving the performance lags with the help of the activity dashboard."
									header="h2"
									img={manageIcon}
									title="Manage customer relationships better"
								/>
								<Box mb={1} mt={4}>
									<Divider light />
								</Box>
								{relationships.map((each, index) => (
									<div key={each.title}>
										<Box my={1} p={2}>
											<Box
												alignItems="center"
												display="flex"
												justifyContent="space-between"
												onClick={() => setActiveRelationships(index === activeRelationships ? -1 : index)}
											>
												<Box color="text.secondary" component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
													{each.title}
												</Box>
												<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
													{index !== activeRelationships ? (
														<AddIcon fontSize="small" />
													) : (
															<RemoveIcon fontSize="small" />
														)}
												</IconButton>
											</Box>
											<Collapse in={activeRelationships === index}>
												<ParagraphTypography fontSize={16} mr={6} mt={2}>
													{each.description}
												</ParagraphTypography>
											</Collapse>
										</Box>
										<Divider light />
									</div>
								))}
							</Box>
						</Box>
					</Grid>
					<Grid item md={6} sm={12}>
						<Box textAlign="right">
							<img alt="Visual of leaderboards" className={classes.img} src={dashboardimg4} />
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Box mb={6} mt={{ sm: 6, xs: 6, md: 10 }}>
				<Divider light />
			</Box>
			<Box component={Container} textAlign="center">
				<AccordionHeader
					alignItems="center"
					alt="Sales outreach "
					description={
						<Box display="flex" justifyContent="center">
							<Box maxWidth={800}>
								Pull out the campaign metrics from the data visualisation dashboard of your CRM system and derive
								action-driven insights.
							</Box>
						</Box>
					}
					display="flex"
					flexDirection="column"
					header="h2"
					img={marketingCampain}
					mb={4}
					title="Track campaigns that generate revenue"
				/>
			</Box>
			<Box className={classes.favToolsBg}>
				<Box component={Container} textAlign="center">
					<Box minHeight={{ md: "240px" }}>
						<Box component={Grid} container justifyContent="center" spacing={4}>
							{campaigns.map((each, index) => (
								<Grid item key={each.title} md={4} sm={12}>
									<Divider light />
									<Box mt={1} mb={0} px={1} pt={1}>
										<Box
											alignItems="center"
											display="flex"
											justifyContent="space-between"
											onClick={() => {
												activeContact[index] = !activeContact[index];
												setActiveContact([...activeContact]);
											}}
										>
											<Box color="text.secondary" component="h3" fontSize="h6.fontSize" fontWeight="600" m={0}>
												{each.title}
											</Box>
											<IconButton aria-label="Expand or Collapse" className={classes.expendButton} color="default">
												{!activeContact[index] ? <AddIcon fontSize="small" /> : <RemoveIcon fontSize="small" />}
											</IconButton>
										</Box>
										<Collapse in={activeContact[index]}>
											<ParagraphTypography fontSize={16} minHeight={129} mr={6} mb={0} mt={2} textAlign="initial">
												{each.description}
											</ParagraphTypography>
										</Collapse>
									</Box>
									<Hidden smDown>
										<Divider light />
									</Hidden>
								</Grid>
							))}
						</Box>
					</Box>
				</Box>
				<Box mb={4} pt={{ sm: 4, xs: 2 }[width] || 8} textAlign="center">
					<Button
						className={classes.bookNowButton}
						color="secondary"
						component={Link}
						size="large"
						to="/contact"
						variant="contained"
					>
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						Build the campaigns
					</Button>
				</Box>
			</Box>
			<Box className={classes.campaignsBg} textAlign="center">
				<img alt="Visual of marketing campaigns conducted in mailchimp" className={classes.img} maxWidth="800px" src={dashboardimg5} />
			</Box>
			<Box mb={4} mt={0}>
				<Divider light />
			</Box>
			<Box component={Container} display="flex" justifyContent="center">
				<Box pb={3}>
					<Box pb={{ md: 6 }} pt={4} textAlign="center">
						<HeaderTypography
							alignItems="center"
							component="h2"
							display="flex"
							// fontSize={48}
							justifyContent="center"
							lineHeight={1.2}
							mb={0}
							mt={2}
						>
							Sales dashboard with intuitive insights
						</HeaderTypography>
						<ParagraphTypography component="h3" fontSize={{ sm: "16px", xs: "18px", md: "18px" }} fontWeight={400} mb={{ md: 3 }} textAlign="center">
							With Pepper Cloud CRM dashboards, go beyond the reports and gain useful insights for your team growth.
						</ParagraphTypography>
					</Box>
					<Grid alignItems="center" container justify="center" spacing={2}>
						<Grid item md={1} sm={1} />
						<Grid item md={5} sm={5} xs={12}>
							<List dense>
								{security.map((each) => (
									<ListItem key={each}>
										<ListItemIcon>
											<CheckIcon className={classes.listItemIcon} />
										</ListItemIcon>
										<ListItemText className={classes.listItemText}>{each}</ListItemText>
									</ListItem>
								))}
							</List>
							<br />
							<br />
							<Box textAlign={{ xs: "center", sm: "center" }[width]}>
								<Button
									className={classes.btn}
									color="secondary"
									component={Link}
									size="large"
									to="/contact"
									variant="contained"
								>
									Grow my business
								</Button>
							</Box>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<img alt="Intuitive CRM dashboard" className={classes.img} height="auto" src={dashboardimg6} />
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box mb={{ md: 6 }} mt={{ md: 10 }}>
				<Divider light />
			</Box>
			<Box component={Container} mb={4} textAlign="center">
				<Box py={8}>
					<HeaderTypography
						alignItems="center"
						component="h2"
						display="flex"
						fontSize={40}
						justifyContent="center"
						lineHeight={1.2}
						mb={2}
						mt={0}
					>
						Our happy customers are accelerating their sales growth
					</HeaderTypography>
					<Box display="flex" justifyContent="center">
						<ParagraphTypography maxWidth={720}>
							Many businesses trust Pepper Cloud CRM software to run their operations globally. Know about our clients
							experience in their words.
						</ParagraphTypography>
					</Box>
				</Box>
				<Container maxWidth="lg">
					<SingleTestimonial {...client} />
				</Container>
			</Box>
			<Box className={classes.requestDemoRoot} px={2} py={{ sm: 6, xs: 6 }[width] || 12}>
				<HeaderTypography color="common.white" fontSize={38} m={0} textAlign="center">
					Every idea begins with a small step
				</HeaderTypography>
				<ParagraphTypography color="common.white" fontSize={24} mt={0} textAlign="center">
					Are you ready to take your step?
				</ParagraphTypography>
				<Box mt={6} textAlign="center">
					<WhiteButton color="primary" component={Link} size="large" to="/contact" variant="contained">
						{`Let's do this!`}
					</WhiteButton>
				</Box>
			</Box>
		</>
	);
};

export default Dashboard;
